// Here you can add other styles
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #738291 !important;
}
.hidden-div {
  display: none !important;
}
.visible-div {
  display: unset !important;
}

/* .table { */
  /* margin-bottom: 0px !important; */
/* } */

.tableNext {
  margin-bottom: 0;
}

.table thead th {
  border-bottom: 0px !important;
}

a:active, a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

@media screen and (min-width: 768px) {
    .rwd-break { display: none; }
}

.aButton {
  cursor: "pointer";
}

.aButton:hover {
  text-decoration: none !important;
}

.search-label {
  flex-grow:1
}

.closeButtonCircle {
  margin-top: 2px !important;
}

.btn-whatsapp {
  color: #fff;
  background-color: #25d366;
  border-color: #25d366;
}

.btn-whatsapp:hover {
  color: #fff;
  background-color: #075e54;
  border-color: #075e54;
}

.btn-whatsapp:focus, .btn-whatsapp.focus {
  box-shadow: 0 0 0 0.2rem rgba(7, 94, 84, 0.5);
}

.btn-whatsapp.disabled, .btn-whatsapp:disabled {
  color: #fff;
  background-color: #25d366;
  border-color: #25d366;
}

.btn-whatsapp:not(:disabled):not(.disabled):active, .btn-telegram:not(:disabled):not(.disabled).active,
.show > .btn-telegram.dropdown-toggle {
  color: #fff;
  background-color: #075e54;
  border-color: #075e54;
}

.btn-telegram:not(:disabled):not(.disabled):active:focus, .btn-telegram:not(:disabled):not(.disabled).active:focus,
.show > .btn-telegram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(18, 140, 126, 0.5);
}

.btn-telegram {
  color: #fff;
  background-color: #229ed9;
  border-color: #229ed9;
}

.btn-telegram:hover {
  color: #fff;
  background-color: #03527e;
  border-color: #03527e;
}

.btn-telegram:focus, .btn-telegram.focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 149, 190, 0.5);
}

.btn-telegram.disabled, .btn-telegram:disabled {
  color: #fff;
  background-color: #229ed9;
  border-color: #229ed9;
}

.btn-telegram:not(:disabled):not(.disabled):active, .btn-telegram:not(:disabled):not(.disabled).active,
.show > .btn-telegram.dropdown-toggle {
  color: #fff;
  background-color: #03527e;
  border-color: #03527e;
}

.btn-telegram:not(:disabled):not(.disabled):active:focus, .btn-telegram:not(:disabled):not(.disabled).active:focus,
.show > .btn-telegram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 233, 239, 0.5);
}

.date-picker-wrapper input:focus {
  outline: none;
}
